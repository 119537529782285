<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="品牌：" prop="brandId">
            <el-radio-group v-model="form.brandId">
              <el-radio v-for="(item, index) in PRINT_BRAND_OPTIONS" :key="index" :label="item.value">{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="类型：" prop="type">
            <el-select v-model="form.type" placeholder="请选择">
              <el-option
                v-for="(item, index) in PRINT_CONFIG_OPTIONS"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片：" prop="image">
            <uploads v-model="form.image"></uploads>
          </el-form-item>
          <el-form-item label="模板：" prop="template">
            <el-input v-model="form.template"  type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="模板示范：" prop="paper">
            <uploads v-model="form.paper"></uploads>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  PRINT_BRAND_OPTIONS,
  PRINT_CONFIG_OPTIONS
} from "@/helpers/constants";

import {
  save,
  updateById,
} from "@/apis/uniprint/moulage";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { getShopList } from "@/apis/auth";
import { reactive } from "vue";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      PRINT_BRAND_OPTIONS:PRINT_BRAND_OPTIONS,
      PRINT_CONFIG_OPTIONS:PRINT_CONFIG_OPTIONS,
      initForm: {
        name: "",
        type: "",
        brandId: "",
        template: "",
        paper:"",
        image:"",
      },
      form: {},
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
        brandId: [{ required: true, message: "品牌不能为空", trigger: "blur" }],
        template: [{ required: true, message: "模板不能为空", trigger: "blur" }],
        paper: [{ required: true, message: "模板示范不能为空", trigger: "blur" }],
        image: [{ required: true, message: "图片不能为空", trigger: "blur" }],
      }
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        const promise = this.mode === "edit" ? updateById : save;
        promise(this.form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
