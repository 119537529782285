<template>
  <div class="panel-page_container">
    <div class="panel-page_header">
      <el-button type="info" class="fa fa-refresh" @click="getList"></el-button>
      <el-button class="" type="primary" @click="addHandle">添加</el-button>
    </div>
    <div class="panel-page_main">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-background="transparent"
        @row-dblclick="editHandle"
        row-key="id"
      >
        <el-table-column
          prop="id"
          label="ID"
          min-width="40"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          min-width="120"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="brandId"
          label="图片"
          min-width="40"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <el-image
              v-if="scope.row.image"
              style="width: 30px; height: 30px"
              :src="scope.row.image.split(',')[0]"
              :preview-src-list="scope.row.image.split(',')"
              preview-teleported
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="paper"
          label="模板示范"
          min-width="90"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <el-image
              v-if="scope.row.paper"
              style="width: 30px; height: 30px"
              :src="scope.row.paper.split(',')[0]"
              :preview-src-list="scope.row.paper.split(',')"
              preview-teleported
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          min-width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="brandId"
          label="品牌"
          min-width="60"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="创建时间"
          min-width="120"
          header-align="center"
          align="center"
          :formatter="
            (row) => $moment.unix(row.createtime).format('yyyy-MM-DD HH:mm:ss')
          "
        >
        </el-table-column>
        <el-table-column
          prop="updatetime"
          label="更新时间"
          min-width="120"
          header-align="center"
          align="center"
           :formatter="
            (row) => $moment.unix(row.updatetime).format('yyyy-MM-DD HH:mm:ss')
          "
        >
        </el-table-column>
        <el-table-column label="操作" header-align="center" align="center">
          <template v-slot="scope">
            <el-button
              class="icon-btn fa fa-pencil"
              type="primary"
              @click="editHandle(scope.row)"
            >
              <!-- 编辑 -->
            </el-button>
            <el-button
              class="icon-btn fa fa-trash"
              type="danger"
              @click="delHandle(scope.row)"
            >
              <!-- 删除 -->
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { USER_STATUS } from "@/helpers/constants";
import { msgErr, msgSuc } from "@/helpers/message";
import {
  listByPage,
  deleteById,
} from "@/apis/uniprint/moulage";
import Sortable from "sortablejs";
const calls = [];
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      USER_STATUS: USER_STATUS,
      tableData: [],
      loading: false,
      initForm: {},
      form: {},
      paginationData: {},
    };
  },
  emits: ["add", "edit"],
  created() {
    console.log(calls);
  },
  mounted() {},
  methods: {
    editHandle(row) {
      this.$emit("edit", row);
    },
    getList() {
      this.loading = true;
      listByPage({type:this.type==="all"?"":this.type,...this.paginationData})
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addHandle() {
      this.$emit("add");
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteById(row.id).then(({ code, msg }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-page_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .panel-page_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
.move-handle {
  background-color: #2c3e50;
  color: #ffffff;
}
</style>
